/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
:root {
    --fontFamily: 'Noto Sans JP', sans-serif;
    --mainColor: #e01a33;
    --optionalColor: #666666;
    --whiteColor: #ffffff;
    --blackColor: #1a2238;
    --fontSize: 15px;
    --transition: .5s;
}
body {
    margin: 0;
    padding: 0;
    color: var(--blackColor);
    font: {
        size: var(--fontSize);
        family: var(--fontFamily);
    };
}
.container {
    max-width: 1230px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: var(--blackColor);
    font-weight: 700;
}
a {
    text-decoration: none;
    outline: 0 !important;
    color: var(--blackColor);
    transition: var(--transition);

    &:hover {
        color: var(--mainColor);
        text-decoration: none;
    }
}
:focus {
    outline: 0 !important;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 100%;
    height: auto;
}
p {
    color: var(--optionalColor);
    font-size: var(--fontSize);
    margin-bottom: 15px;
    line-height: 1.8;

    &:last-child {
        margin-bottom: 0;
    }
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.bg-f9f9f9 {
    background-color: #f9f9f9;
}
.bg-f5f5f5 {
    background-color: #f5f5f5;
}
.bg-d8eafe {
    background-color: #d8eafe;
}
.bg-F9FAFB {
    background-color: #F9FAFB;
}
/*default-btn*/
.default-btn {
    display: inline-block;
    text-align: center;
    background-color: var(--mainColor);
    color: var(--whiteColor);
    padding: 13.5px 60px 13px 35px;
    position: relative;
    transition: var(--transition);
    border-radius: 3px;
    border: none;
    font: {
        size: var(--fontSize);
        weight: 600;
    };
    i {
        position: absolute;
        right: 35px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
    }
    &:hover {
        color: var(--whiteColor);
        background-color: var(--blackColor);
    }
}
/*section-title*/
.section-title {
    max-width: 650px;
    text-align: center;
    margin: {
        left: auto;
        right: auto;
        top: -6px;
        bottom: 50px;
    };
    .sub-title {
        display: block;
        margin-bottom: 12px;
        color: var(--mainColor);
        font: {
            size: 15px;
            weight: 500;
        };
    }
    h2 {
        font-size: 35px;
        margin-bottom: 0;
    }
    p {
        max-width: 540px;
        margin: {
            left: auto;
            right: auto;
            top: 15px;
        };
    }
}
/*form-control*/
.form-control {
    height: 52px;
    border-radius: 0;
    padding-left: 15px;
    border: none !important;
    color: var(--blackColor);
    font-size: var(--fontSize);
    box-shadow: unset !important;
    transition: var(--transition);
    background-color: #f5f5f5 !important;

    &::placeholder {
        color: #999999;
        transition: var(--transition);
    }
    &:focus {
        &::placeholder {
            color: transparent;
        }
    }
}
textarea.form-control {
    padding-top: 15px;
    height: auto;
}

/*extra-css*/
.services-area {
    z-index: 1;
    position: relative;

    &.bg-f5f5f5 {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            height: 100px;
            background-color: var(--whiteColor);
        }
    }
    &.bg-d8eafe {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            height: 100px;
            background-color: var(--whiteColor);
        }
    }
}
.divider {
    width: 100%;
    position: absolute;
    height: 360px;
    pointer-events: none;
    -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
    mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
    -webkit-mask-size: 100% 101%;
    mask-size: 100% 101%;
    background: var(--whiteColor);
    bottom: 0;
    left: 0;
    z-index: -1;
}
.page-title-area {
    background: {
        image: url(assets/img/page-title-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.page-title-content {
    text-align: center;

    h1 {
        color: var(--whiteColor);
        margin-bottom: 0;
        font-size: 40px;
    }
}
.partner-area {
    &.partner-inner {
        margin-top: -120px;
        background-color: var(--whiteColor);
        padding: {
            top: 40px;
            bottom: 30px;
        };
        .inner {
            max-width: 1075px;
            padding: {
                left: 75px;
                right: 130px;
            };
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}
.pagination-area {
    margin-top: 30px;

    .ngx-pagination {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: var(--blackColor);
            margin: {
                left: 5px;
                right: 5px;
            };
            font: {
                weight: 600;
                size: var(--fontSize);
            };
            &.disabled {
                color: var(--blackColor);
                background-color: transparent;
                border: 2px solid #f5f3f3;
                padding: 6px 15px;
            }
            a {
                padding: 6px 15px;
                color: var(--blackColor);
                transition: var(--transition);
                border: 2px solid #f5f3f3;
                background-color: transparent;
                
                &:hover, &.current {
                    background-color: transparent;
                    color: var(--blackColor);
                    border-color: var(--mainColor);
                }
            }
            &.current {
                background-color: transparent;
                color: var(--blackColor);
                border: 2px solid var(--mainColor);
                padding: 6px 15px;
            }
            &.pagination-previous {
                &::before {
                    position: relative;
                    top: -1px;
                    margin: 0;
                }
                a {
                    &::before {
                        position: relative;
                        top: -1px;
                        margin: 0;
                    }
                }
            }
            &.pagination-next {
                &::after {
                    position: relative;
                    top: -1px;
                    margin: 0;
                }
                a {
                    &::after {
                        position: relative;
                        top: -1px;
                        margin: 0;
                    }
                }
            }
        }
    }
}
.widget-area {
    .widget {
        padding: 25px;
        margin-bottom: 30px;
        background-color: #f5f5f5;

        .widget-title {
            font-size: 19px;
            border-bottom: 1px solid #e3e1e1;
            margin: {
                bottom: 25px;
                top: -25px;
                left: -25px;
                right: -25px;
            };
            padding: {
                top: 16px;
                bottom: 15px;
                left: 25px;
                right: 25px;
            };
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .widget_search {
        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                width: 100%;
                border: none;
                height: 50px;
                display: block;
                border-radius: 5px;
                padding: 0 0 0 15px;
                color: var(--blackColor);
                font-size: var(--fontSize);
                background-color: var(--whiteColor);

                &::placeholder {
                    color: var(--optionalColor);
                    transition: var(--transition);
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                top: 5px;
                right: 5px;
                padding: 0;
                width: 40px;
                border: none;
                height: 40px;
                font-size: 20px;
                border-radius: 5px;
                position: absolute;
                color: var(--mainColor);
                background-color: #f5f5f5;
                transition: var(--transition);

                i {
                    left: 0;
                    right: 0;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                }
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
    .widget_naxai_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            margin-bottom: 20px;
            position: relative;
            padding-left: 95px;

            .thumb {
                top: 0;
                left: 0;
                width: 80px;
                float: unset;
                height: 100%;
                display: block;
                position: absolute;

                .fullimage {
                    width: 80px;
                    height: 100%;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                        background-image: url(assets/img/blog/blog-img1.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog/blog-img2.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog/blog-img3.jpg);
                    }
                    &.bg4 {
                        background-image: url(assets/img/blog/blog-img4.jpg);
                    }
                }
                &::before, &::after {
                    content: '';
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    position: absolute;
                    background-color: var(--whiteColor);
                    transform: translate(-50%, -50%);
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                .title {
                    font-size: 14px;
                    line-height: 1.5;
                    margin-bottom: 8px;

                    a {
                        display: inline-block;
                    }
                }
                .meta {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;
        
                    li {
                        font-size: 14px;
                        margin-right: 15px;
                        position: relative;
                        padding-left: 20px;
                        display: inline-block;
                        color: var(--optionalColor);

                        i {
                            left: 0;
                            top: 4px;
                            position: absolute;
                            color: var(--mainColor);
                        }
                        a {
                            display: inline-block;
                            color: var(--optionalColor);

                            &:hover {
                                color: var(--mainColor);
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: block;
                margin-bottom: 13px;

                a {
                    display: flex;
                    position: relative;
                    align-items: center;
                    display: inline-block;
                    font-size: var(--fontSize);
                    color: var(--optionalColor);

                    i {
                        width: 28px;
                        height: 28px;
                        line-height: 30px;
                        text-align: center;
                        border-radius: 3px;
                        margin-right: 10px;
                        color: var(--mainColor);
                        transition: var(--transition);
                        background-color: var(--whiteColor);
                    }
                    &:hover {
                        color: var(--blackColor);

                        i {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .tagcloud {
        margin-top: -8px;

        a {
            border: none;
            border-radius: 3px;
            display: inline-block;
            padding: 7px 15px 6px;
            color: var(--optionalColor);
            font-size: 14px !important;
            background: var(--whiteColor);
            margin: {
                top: 8px;
                right: 4px;
            };
            &:hover {
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }
        }
    }
}

/*owl-carousel-css*/
.main-services-slides {
    .owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: -50px;
                top: 50%;
                transform: translateY(-50%);
                width: 35px;
                height: 35px;
                border: 1px solid var(--mainColor);
                color: var(--blackColor);
                background-color: transparent;
                border-radius: 50%;
                padding: 0;
                margin: 0;
                transition: var(--transition);
                font-size: 20px;

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-next {
                    left: auto;
                    right: -50px;
                }
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
}
.services-slides {
    .owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                margin-top: 0;
            }
        }
        .owl-dots {
            margin-top: 10px !important;

            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 8px 0 0;
                    background: transparent;
                    border: 1px solid #8f8f8f;
                    transition: var(--transition);
                    border-radius: 50%;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #8f8f8f;
                        border-radius: 50%;
                        margin: 2px;
                        transition: var(--transition);
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: var(--mainColor);

                        &::before {
                            opacity: 1;
                            visibility: visible;
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
}
.testimonials-slides {
    .owl-theme {
        margin-top: 35px;

        .owl-nav {
            &.disabled+.owl-dots {
                margin-top: 0;
            }
        }
        .owl-dots {
            right: 0;
            bottom: 9px;
            position: absolute;
            margin-top: 10px !important;

            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 8px 0 0;
                    background: transparent;
                    border: 1px solid #8f8f8f;
                    transition: var(--transition);
                    border-radius: 50%;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #8f8f8f;
                        border-radius: 50%;
                        margin: 2px;
                        transition: var(--transition);
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: var(--mainColor);

                        &::before {
                            opacity: 1;
                            visibility: visible;
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
}
.testimonials-slides-two {
    .owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                margin-top: 0;
            }
        }
        .owl-dots {
            right: 0;
            left: 0;
            bottom: 35px;
            position: absolute;
            margin-top: 10px !important;

            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 8px 0 0;
                    background: transparent;
                    border: 1px solid #8f8f8f;
                    transition: var(--transition);
                    border-radius: 50%;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #8f8f8f;
                        border-radius: 50%;
                        margin: 2px;
                        transition: var(--transition);
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: var(--mainColor);

                        &::before {
                            opacity: 1;
                            visibility: visible;
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
}
.team-slides {
    .owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                margin-top: 0;
            }
        }
        .owl-dots {
            margin-top: 10px !important;

            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 8px 0 0;
                    background: transparent;
                    border: 1px solid #8f8f8f;
                    transition: var(--transition);
                    border-radius: 50%;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #8f8f8f;
                        border-radius: 50%;
                        margin: 2px;
                        transition: var(--transition);
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: var(--mainColor);

                        &::before {
                            opacity: 1;
                            visibility: visible;
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
}
.partner-slides {
    .owl-theme {
        .owl-stage {
            display: flex;
            align-items: center;
        }
    }
}
.home-slides {
    .owl-theme {
        .owl-nav {
            text-align: right;
            margin: {
                top: 50px;
                right: 350px;
            };
            [class*=owl-] {
                margin: 0 5px;
                padding: 0;
                width: 45px;
                height: 45px;
                font-size: 25px;
                border-radius: 50%;
                position: relative;
                color: var(--blackColor);
                transition: var(--transition);
                border: 1px solid var(--mainColor);
                background-color: var(--whiteColor);
                text-align: center;
                
                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {

    body {
        font-size: 13px;
    }
    p {
        font-size: 13px;
    }
    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        };
    }
    .pt-100 {
        padding-top: 60px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .ptb-70 {
        padding: {
            top: 30px;
            bottom: 30px;
        };
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .default-btn {
        font-size: 12px;
        padding: 12px 39px 12px 20px;

        i {
            right: 20px;
            font-size: 18px;
            margin-top: .5px;
        }
    }
    .section-title {
        max-width: 100%;
        margin: {
            top: -5px;
            bottom: 40px;
        };
        .sub-title {
            font-size: 13px;
        }
        h2 {
            font-size: 24px;
            line-height: 1.4;

            br {
                display: none;
            }
        }
        p {
            max-width: 100%;
        }
    }
    .container {
        max-width: 100%;
    }
    .form-control {
        height: 50px;
        font-size: 14px;
    }

    .naxai-responsive-nav {
        .naxai-responsive-menu {
            &.mean-container {
                .mean-nav {
                    margin-top: 51px;
                    background-color: var(--whiteColor);

                    ul {
                        font-size: 14px;
                        border: none !important;

                        li {
                            a {
                                color: var(--blackColor);
                                border-top-color: #DBEEFD;
                                text-transform: capitalize;

                                i {
                                    display: none;
                                }
                                &.mean-expand {
                                    width: 50%;
                                    height: 28px;
                                    text-align: right;
                                    padding: 11px!important;
                                    background: transparent !important;
                                    border: {
                                        left: none !important;
                                        bottom: none !important;
                                    };
                                }
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    height: 350px;
                    overflow-y: scroll;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    top: 0;
                    padding: 0;
                    width: 35px;
                    height: 30px;
                    padding-top: 6px;
                    color: var(--blackColor);
                    font-family: var(--fontFamily);

                    span {
                        background: var(--blackColor);
                        height: 4px;
                        margin-top: -6px;
                        border-radius: 3px;
                        position: relative;
                        top: 8px;
                    }
                }
            }
            .mean-bar {
                background: transparent;
                position: absolute;
                z-index: 999;
                padding: 0;
            }
            .navbar-nav {
                .nav-item {
                    &.megamenu {
                        .dropdown-menu {
                            .nav-item {
                                width: 90%;
                                overflow: hidden;
                                padding: 1em 5% 5px;
    
                                .row {
                                    display: block;
                                    flex-wrap: unset;
                                    margin: {
                                        left: 0;
                                        right: 0;
                                        top: -20px;
                                    };
                                    .col {
                                        flex-basis: unset;
                                        flex-grow: unset;
                                        max-width: 100%;
                                        padding: {
                                            left: 0;
                                            right: 0;
                                            top: 20px;
                                        };
                                        &:last-child {
                                            margin-bottom: 15px;
                                        }
                                    }
                                }
                                a {
                                    width: 100%;
                                    float: unset;
                                    display: block;

                                    &.p-0 {
                                        border: none !important;
                                        margin-top: 15px;
                                    }
                                }
                            }
                            .submenu-title {
                                position: relative;
                                padding-bottom: 8px;
                                color: var(--blackColor);
                                text-transform: uppercase;
                                border-bottom: 1px solid #eeeeee;
                                margin: {
                                    bottom: 5px;
                                    top: 25px;
                                    left: 0;
                                    right: 0;
                                };
                                font: {
                                    size: 12.5px;
                                    weight: 600;
                                };
                                &::before {
                                    width: 30px;
                                    height: 1px;
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    bottom: -1px;
                                    background-color: var(--mainColor);
                                }
                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                            a {
                                border-bottom: none !important;
                            }
                            .mean-expand {
                                display: none !important;
                            }
                            .megamenu-submenu {
                                width: auto;
                                overflow: hidden;
                                display: block !important;
                                padding: {
                                    left: 0;
                                    right: 0;
                                };
                                li {
                                    a {
                                        padding: 0;
                                        border: none;
                                        margin-top: 15px;
                                        font-weight: 400;
    
                                        &:hover, &.active {
                                            color: var(--mainColor);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                text-align: center;
                margin: {
                    top: 30px;
                    right: 0;
                };
                [class*=owl-] {
                    width: 38px;
                    height: 38px;
                    font-size: 20px;
                }
            }
        }
    }

    .main-services-slides {
        .owl-theme {
            .owl-nav {
                margin-bottom: 0;

                [class*=owl-] {
                    left: 0;
                    top: -15px;
                    width: 30px;
                    height: 30px;
                    font-size: 20px;
                    transform: unset;
                    position: absolute;
                    background-color: var(--whiteColor);

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                    &:hover {
                        background-color: var(--mainColor);
                    }
                }
            }
        }
    }
    .services-slides {
        .owl-theme {
            .owl-dots {
                margin-top: 0 !important;
            }
        }
    }

    .testimonials-slides {
        .owl-theme {
            margin-top: 25px;

            .owl-dots {
                bottom: 0;
                position: relative;
                margin-top: 30px !important;
            }
        }
    }
    .testimonials-slides-two {
        .owl-theme {
            .owl-dots {
                bottom: 0;
                position: relative;
                margin: {
                    top: 0 !important;
                    bottom: 30px;
                };
            }
        }
    }

    .partner-area {
        &.pb-100 {
            padding-top: 60px;
        }
        &.partner-inner {
            margin-top: 0;
            padding: {
                top: 40px;
                bottom: 0;
            };
            .inner {
                max-width: 100%;
                padding: {
                    left: 15px;
                    right: 15px;
                };
            }
        }
    }
    .divider {
        display: none;
    }
    
    .team-slides {
        .owl-theme {
            .owl-dots {
                margin-top: 0 !important;
            }
        }
    }

    .page-title-area {
        padding: {
            top: 60px;
            bottom: 60px;
        };
    }
    .page-title-content {
        h1 {
            font-size: 25px;
        }
    }

    .pagination-area {
        margin-top: 10px;
    }

    .widget-area {
        margin-top: 40px;

        .widget {
            padding: 20px;

            .widget-title {
                font-size: 17px;
                margin: {
                    bottom: 20px;
                    top: -20px;
                    left: -20px;
                    right: -20px;
                };
                padding: {
                    top: 15px;
                    bottom: 15px;
                    left: 20px;
                    right: 20px;
                };
            }
        }
        .widget_naxai_posts_thumb {
            .item {
                padding-left: 90px;

                .thumb {
                    width: 70px;

                    .fullimage {
                        width: 70px;
                    }
                }
                .info {
                    .title {
                        font-size: 12.5px;
                    }
                    .meta {
                        li {
                            font-size: 13px;
                            margin-right: 10px;
                            padding-left: 16px;

                            i {
                                top: 3px;
                            }
                        }
                    }
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    a {
                        font-size: 13px;

                        i {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .tagcloud a {
            padding: 7px 14px;
            margin-right: 4px;
            font-size: 12px !important;
        }
    }

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {

    .container {
        max-width: 540px;
    }

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

    body {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }
    .ptb-100 {
        padding: {
            top: 80px;
            bottom: 80px;
        };
    }
    .pt-100 {
        padding-top: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .ptb-70 {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .pt-70 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .container {
        max-width: 720px;
    }
    .default-btn {
        padding: 13px 50px 13px 30px;
        font-size: 12.5px;

        i {
            right: 30px;
            font-size: 15px;
        }
    }
    .section-title {
        max-width: 500px;
        margin: {
            top: -4px;
            bottom: 45px;
        };
        h2 {
            font-size: 28px;
        }
    }

    .naxai-responsive-nav {
        .naxai-responsive-menu {
            &.mean-container {
                .mean-nav {
                    margin-top: 51px;
                    background-color: var(--whiteColor);

                    ul {
                        font-size: 14px;
                        border: none !important;

                        li {
                            a {
                                color: var(--blackColor);
                                border-top-color: #DBEEFD;
                                text-transform: capitalize;

                                i {
                                    display: none;
                                }
                                &.mean-expand {
                                    width: 50%;
                                    height: 28px;
                                    text-align: right;
                                    padding: 11px!important;
                                    background: transparent !important;
                                    border: {
                                        left: none !important;
                                        bottom: none !important;
                                    };
                                }
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    height: 350px;
                    overflow-y: scroll;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    top: 0;
                    padding: 0;
                    width: 35px;
                    height: 30px;
                    padding-top: 6px;
                    color: var(--blackColor);
                    font-family: var(--fontFamily);

                    span {
                        background: var(--blackColor);
                        height: 4px;
                        margin-top: -6px;
                        border-radius: 3px;
                        position: relative;
                        top: 8px;
                    }
                }
            }
            .mean-bar {
                background: transparent;
                position: absolute;
                z-index: 999;
                padding: 0;
            }
            .navbar-nav {
                .nav-item {
                    &.megamenu {
                        .dropdown-menu {
                            .nav-item {
                                width: 90%;
                                overflow: hidden;
                                padding: 1em 5% 5px;
    
                                .row {
                                    display: block;
                                    flex-wrap: unset;
                                    margin: {
                                        left: 0;
                                        right: 0;
                                        top: -20px;
                                    };
                                    .col {
                                        flex-basis: unset;
                                        flex-grow: unset;
                                        max-width: 100%;
                                        padding: {
                                            left: 0;
                                            right: 0;
                                            top: 20px;
                                        };
                                        &:last-child {
                                            margin-bottom: 15px;
                                        }
                                    }
                                }
                                a {
                                    width: 100%;
                                    float: unset;
                                    display: block;

                                    &.p-0 {
                                        border: none !important;
                                        margin-top: 15px;
                                    }
                                }
                            }
                            .submenu-title {
                                position: relative;
                                padding-bottom: 8px;
                                color: var(--blackColor);
                                text-transform: uppercase;
                                border-bottom: 1px solid #eeeeee;
                                margin: {
                                    bottom: 5px;
                                    top: 25px;
                                    left: 0;
                                    right: 0;
                                };
                                font: {
                                    size: 12.5px;
                                    weight: 600;
                                };
                                &::before {
                                    width: 30px;
                                    height: 1px;
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    bottom: -1px;
                                    background-color: var(--mainColor);
                                }
                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                            a {
                                border-bottom: none !important;
                            }
                            .mean-expand {
                                display: none !important;
                            }
                            .megamenu-submenu {
                                width: auto;
                                overflow: hidden;
                                display: block !important;
                                padding: {
                                    left: 0;
                                    right: 0;
                                };
                                li {
                                    a {
                                        padding: 0;
                                        border: none;
                                        margin-top: 15px;
                                        font-weight: 400;
    
                                        &:hover, &.active {
                                            color: var(--mainColor);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                text-align: center;
                margin: {
                    top: 40px;
                    right: 0;
                };
            }
        }
    }

    .partner-area {
        &.partner-inner {
            margin-top: 0;
            padding: {
                top: 80px;
                bottom: 0;
            };
            .inner {
                max-width: 720px;
                padding: {
                    left: 15px;
                    right: 15px;
                };
                margin: {
                    left: auto;
                    right: auto;
                };
            }
        }
    }
    
    .main-services-slides {
        .owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 0;

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }

    .divider {
        display: none;
    }

    .page-title-area {
        padding: {
            top: 85px;
            bottom: 80px;
        };
    }
    .page-title-content {
        h1 {
            font-size: 34px;
        }
    }

    .pagination-area {
        margin-top: 20px;
    }
    
    .widget-area {
        margin-top: 40px;

        .widget {
            .widget-title {
                font-size: 18px;
            }
        }
    }

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .container {
        max-width: 960px;
    }
    .default-btn {
        font-size: 13px;
        padding: 13.5px 60px 13px 35px;

        i {
            font-size: 20px;
        }
    }
    .section-title {
        max-width: 555px;

        h2 {
            font-size: 30px;
        }
    }

    .naxai-responsive-nav {
        .naxai-responsive-menu {
            &.mean-container {
                .mean-nav {
                    margin-top: 51px;
                    background-color: var(--whiteColor);

                    ul {
                        font-size: 14px;
                        border: none !important;

                        li {
                            a {
                                color: var(--blackColor);
                                border-top-color: #DBEEFD;
                                text-transform: capitalize;

                                i {
                                    display: none;
                                }
                                &.mean-expand {
                                    width: 50%;
                                    height: 28px;
                                    text-align: right;
                                    padding: 11px!important;
                                    background: transparent !important;
                                    border: {
                                        left: none !important;
                                        bottom: none !important;
                                    };
                                }
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    height: 350px;
                    overflow-y: scroll;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    top: 0;
                    padding: 0;
                    width: 35px;
                    height: 30px;
                    padding-top: 6px;
                    color: var(--blackColor);
                    font-family: var(--fontFamily);

                    span {
                        background: var(--blackColor);
                        height: 4px;
                        margin-top: -6px;
                        border-radius: 3px;
                        position: relative;
                        top: 8px;
                    }
                }
            }
            .mean-bar {
                background: transparent;
                position: absolute;
                z-index: 999;
                padding: 0;
            }
            .navbar-nav {
                .nav-item {
                    &.megamenu {
                        .dropdown-menu {
                            .nav-item {
                                width: 90%;
                                overflow: hidden;
                                padding: 1em 5% 5px;
    
                                .row {
                                    display: block;
                                    flex-wrap: unset;
                                    margin: {
                                        left: 0;
                                        right: 0;
                                        top: -20px;
                                    };
                                    .col {
                                        flex-basis: unset;
                                        flex-grow: unset;
                                        max-width: 100%;
                                        padding: {
                                            left: 0;
                                            right: 0;
                                            top: 20px;
                                        };
                                        &:last-child {
                                            margin-bottom: 15px;
                                        }
                                    }
                                }
                                a {
                                    width: 100%;
                                    float: unset;
                                    display: block;

                                    &.p-0 {
                                        border: none !important;
                                        margin-top: 15px;
                                    }
                                }
                            }
                            .submenu-title {
                                position: relative;
                                padding-bottom: 8px;
                                color: var(--blackColor);
                                text-transform: uppercase;
                                border-bottom: 1px solid #eeeeee;
                                margin: {
                                    bottom: 5px;
                                    top: 25px;
                                    left: 0;
                                    right: 0;
                                };
                                font: {
                                    size: 12.5px;
                                    weight: 600;
                                };
                                &::before {
                                    width: 30px;
                                    height: 1px;
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    bottom: -1px;
                                    background-color: var(--mainColor);
                                }
                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                            a {
                                border-bottom: none !important;
                            }
                            .mean-expand {
                                display: none !important;
                            }
                            .megamenu-submenu {
                                width: auto;
                                overflow: hidden;
                                display: block !important;
                                padding: {
                                    left: 0;
                                    right: 0;
                                };
                                li {
                                    a {
                                        padding: 0;
                                        border: none;
                                        margin-top: 15px;
                                        font-weight: 400;
    
                                        &:hover, &.active {
                                            color: var(--mainColor);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                text-align: right;
                margin-right: 155px;
            }
        }
    }

    .main-services-slides {
        .owl-theme {
            .owl-nav {
                margin-top: 0;
                margin-bottom: 30px;

                [class*=owl-] {
                    top: 0;
                    left: 0;
                    margin: 0 4px;
                    transform: unset;
                    position: relative;

                    &.owl-next {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }

    .partner-area {
        &.partner-inner {
            margin-top: 0;
            padding: {
                top: 90px;
                bottom: 0;
            };
            .inner {
                max-width: 960px;
                padding: {
                    left: 15px;
                    right: 15px;
                };
                margin: {
                    left: auto;
                    right: auto;
                };
            }
        }
    }

    .page-title-content {
        h1 {
            font-size: 35px;
        }
    }

    .widget-area {
        .widget {
            padding: 20px;

            .widget-title {
                font-size: 18px;
                margin: {
                    bottom: 20px;
                    top: -20px;
                    left: -20px;
                    right: -20px;
                };
                padding: {
                    top: 17px;
                    bottom: 15px;
                    left: 20px;
                    right: 20px;
                };
            }
        }
    }

}

/* Min width 1200px to Max width 1355px */
@media only screen and (min-width: 1200px) and (max-width: 1355px) {

    .container {
        max-width: 1140px;
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                text-align: center;
                margin-right: 0;
            }
        }
    }

    .main-services-slides {
        .owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: -40px;

                    &.owl-next {
                        right: -40px;
                    }
                }
            }
        }
    }

    .partner-area {
        &.partner-inner {
            margin-top: -110px;

            .inner {
                max-width: 960px;
                padding: {
                    left: 45px;
                    right: 190px;
                };
            }
        }
    }

}

/* Min width 1356px to Max width 1535px */
@media only screen and (min-width: 1356px) and (max-width: 1535px) {

    .partner-area {
        &.partner-inner {
            margin-top: -115px;

            .inner {
                max-width: 960px;
                padding: {
                    left: 50px;
                    right: 140px;
                };
            }
        }
    }

}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {

    .partner-area {
        &.partner-inner {
            .inner {
                max-width: 1190px;
                padding: {
                    left: 165px;
                    right: 70px;
                };
            }
        }
    }

}